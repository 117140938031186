<template>
  <v-footer role="contentinfo">
    <v-container fluid>
      <v-row class="pre-footer">
        <v-container>
          <v-row>
            <v-col sm="6" md="6" lg="6" class="pl-5">
              <a href="https://www.regione.piemonte.it/web/" target="_blank">
                <img
                  alt="Regione Piemonte"
                  src="../../assets/stylesheets/im/logo-regione.png"
                />
              </a>
            </v-col>
            <v-col sm="6" md="6" lg="6" class="logo-dx pl-5">
              <a href="http://www.arpa.piemonte.it/" target="_blank">
                <img
                  alt="Arpa Piemonte"
                  src="../../assets/stylesheets/im/arpa-s.png"
                />
              </a>
            </v-col>
          </v-row>
        </v-container>
      </v-row>

      <v-row class="footer">
        <v-container>
          <v-row class="border-w">
            <v-col>
              <h3>Regione Piemonte</h3>
              <p>
                Direzione Ambiente, Energia e Territorio<br />Via Principe
                Amedeo, 17 Torino<br />011-4321413
              </p>
            </v-col>
            <v-col>
              <h3>Arpa Piemonte</h3>
              <p>
                Dipartimento<br />Rischi naturali e ambientali<br />Via Pio VII,
                9 Torino<br />
                011-19681350
              </p>
            </v-col>
            <v-col class="d-none d-sm-block">
              SEGUICI SU
              <ul class="social">
                <li>
                  <a
                    href="http://twitter.com/regionepiemonte"
                    target="_blank"
                    title="Twitter"
                  >
                    <span class="sr-only">Twitter</span>
                    <span class="fa-stack fa-1x" aria-hidden="true">
                      <em class="fas fa-circle fa-stack-2x"></em>
                      <em class="fab fa-twitter fa-stack-1x fa-inverse"></em>
                    </span>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.facebook.com/regione.piemonte.official/"
                    title="Facebook"
                    target="_blank"
                  >
                    <span class="sr-only">Facebook</span>
                    <span class="fa-stack fa-1x" aria-hidden="true">
                      <em class="fas fa-circle fa-stack-2x"></em>
                      <em class="fab fa-facebook-f fa-stack-1x fa-inverse"></em>
                    </span>
                  </a>
                </li>
                <li>
                  <a
                    href="http://www.youtube.com/user/LaRegionePiemonte"
                    title="Youtube"
                    target="_blank"
                  >
                    <span class="sr-only">Youtube</span>
                    <span class="fa-stack fa-1x" aria-hidden="true">
                      <em class="fas fa-circle fa-stack-2x"></em>
                      <em class="fab fa-youtube fa-stack-1x fa-inverse"></em>
                    </span>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/company/regione-piemonte"
                    title="Linkedin"
                    target="_blank"
                  >
                    <span class="sr-only">Linkedin</span>
                    <span class="fa-stack fa-1x" aria-hidden="true">
                      <em class="fas fa-circle fa-stack-2x"></em>
                      <em
                        class="fab fa-linkedin-in fa-stack-1x fa-inverse"
                      ></em>
                    </span>
                  </a>
                </li>
                <li>
                  <a
                    href="http://www.flickr.com/photos/regionepiemonte"
                    title="Flickr"
                    target="_blank"
                  >
                    <span class="sr-only">Flickr</span>
                    <span class="fa-stack fa-1x" aria-hidden="true">
                      <em class="fas fa-circle fa-stack-2x"></em>
                      <em class="fab fa-flickr fa-stack-1x fa-inverse"></em>
                    </span>
                  </a>
                </li>
              </ul>
            </v-col>
          </v-row>
          <v-row class="border-w">
            <v-col class="sm-12 md-6 lg-6">
              <p class="small">Questo servizio è parte di</p>
              <img
                alt="Piemonte verso un presente sostenibile"
                src="../../assets/stylesheets/im/logo-piemonte.png"
              />
            </v-col>
            <v-col class="sm-12 md-6 lg-6 logo-dx">
              <a
                href="http://www.csipiemonte.it"
                target="_blank"
                title="Il link si apre in una nuova finestra"
              >
                <img
                  alt="CSI Piemonte"
                  src="../../assets/stylesheets/im/logocsi.png"
                />
              </a>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="12" md="12" lg="12">
              <div class="button-footer">
                <v-btn outlined :to="{ name: 'cookie' }">Cookie policy</v-btn>
                <v-btn outlined :to="{ name: 'privacy' }">Privacy policy</v-btn>
                <v-btn outlined :to="{ name: 'accessibilita' }">
                  Accessibilità
                </v-btn>
                <a
                  href="https://form.agid.gov.it/view/f371a9e2-13bf-4234-a638-f3fb8f9c86dd/"
                  title="Il link si apre in una nuova finestra"
                  target="_blank"
                  class="v-btn v-btn--depressed v-btn--flat v-btn--outlined v-btn--router theme--light v-size--default"
                >
                  <span class="v-btn__content"
                    >Dichiarazione di accessibilià</span
                  ></a
                >
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
export default {
  name: "Footer"
};
</script>
